import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faClock, faGlobe, faPhone, faEnvelope, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin, faTwitter, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useUrlContext } from '../Context/UrlContext'; // Import the custom hook to access URL context

function Footer() {
  const { aboutData, socailMedia ,lastSegment } = useUrlContext(); // Get aboutData from the context

  // If aboutData is not available, return null or a loading indicator
  if (!aboutData) {
    return null; // or return a loading indicator like <p>Loading...</p>
  }

  const {youtube ,whatsapp,twitter,linkedin,instagram,facebook} = socailMedia;

 
  // Destructure data from aboutData for easier access
  const { address, contactNumber, email, logoid } = aboutData;
  const encodedAddress = encodeURIComponent(address);


  return (
       <footer id="footer" className='footer'>
      <div className="footer-top py-5">
        <div className="container">
          <div className="row">
            {/* Location Section */}
            <div className="col-md-4 mb-4">
            {/* <h1 className="font-weight-bold mb-4" style={{ fontFamily: "Arial, sans-serif",fontSize: "2.5rem"  }}>Location</h1> */}
            <div className='text-start my-2'>
              <img src={logoid} alt='logo' width={150} className='my-2' style={{borderRadius:'5px',marginLeft:'20px'}} />
            </div>
              <div className="mb-3">
                <div className="d-flex align-items-start">
                <a href={`https://www.google.com/maps?q=${encodedAddress}`} target='_blank'>
                <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-3" style={{ borderRadius: '50%', width: '30px', height: '30px',marginLeft:'10px',padding:'18px' }}>
                <FontAwesomeIcon icon={faMapMarkerAlt} className="text-white mr-3" size="lg" />
               </div>
               </a>
                  <div>
                    {/* <span className="font-weight-bold">Address</span> */}
                    <p>
                      {address}
                    </p>
                  </div>
                  
                </div>
                <div className='mt-3 mx-3'>
                <a  className="font-weight-bold text-white" style={{textDecoration:'none'}}>Socail Media</a>
                <div className='d-flex mt-2'>
                <a href={`https://www.youtube.com/channel/${youtube}`} target='_blank'>
                <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-1" style={{ borderRadius: '50%', width: '30px', height: '30px',marginLeft:'10px',padding:'18px', cursor:'pointer' }}>
                 <FontAwesomeIcon icon={faYoutube} className="text-white mr-3" size="lg" />
                  </div>
                  </a> 

                  <a href={`https://twitter.com/${twitter}`} target='_blank'>
                  <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-1" style={{ borderRadius: '50%', width: '30px', height: '30px',marginLeft:'10px',padding:'18px', cursor:'pointer' }}>
                  <FontAwesomeIcon icon={faTwitter} className="text-white mr-3" size="lg" />
                  </div>
                  </a>

                  <a href={`https://www.linkedin.com/in/${linkedin}`} target='_blank'>
                  <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-1" style={{ borderRadius: '50%', width: '30px', height: '30px',marginLeft:'10px',padding:'18px', cursor:'pointer' }}>
                  <FontAwesomeIcon icon={faLinkedin} className="text-white mr-3" size="lg" />
                  </div>
                  </a>

                  <a href={`https://www.instagram.com/${instagram}`} target='_blank'>
                  <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-1" style={{ borderRadius: '50%', width: '30px', height: '30px',marginLeft:'10px',padding:'18px', cursor:'pointer' }}>
                  <FontAwesomeIcon icon={faInstagram} className="text-white mr-3" size="lg" />
                  </div>
                  </a>

                  <a href={`https://www.facebook.com/${facebook}`} target='_blank'>
                  <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-1" style={{ borderRadius: '50%', width: '30px', height: '30px',marginLeft:'10px',padding:'18px', cursor:'pointer' }}>
                  <FontAwesomeIcon icon={faFacebook} className="text-white mr-3" size="lg" />
                  </div>
                   </a>
                </div>
                </div>
              </div>
              
            </div>

            {/* Contact Info Section */}
            <div className="col-md-4 mb-4">
            <h1 className="font-weight-bold mb-4" style={{ fontFamily: "Arial, sans-serif",fontSize: "2.5rem"  }}>Contact Info</h1>
              <div className="mb-3">
                <div className="d-flex align-items-start">
                <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-3" style={{ borderRadius: '50%', width: '30px', height: '30px',marginLeft:'10px',padding:'18px' }}>
                  <FontAwesomeIcon icon={faGlobe} className="text-white mr-3" size="lg" />
                  </div>
                  <div>
                    <a className="font-weight-bold text-white" style={{textDecoration:'none'}}>Website</a>
                    <p>qrdcard.com/{lastSegment}</p>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-start">
                <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-3" style={{ borderRadius: '50%', width: '30px', height: '30px',marginLeft:'10px',padding:'18px' }}>
                  <FontAwesomeIcon icon={faPhone} className="text-white mr-3" size="lg" />
                  </div>
                  <div>
                    <span className="font-weight-bold">Call Us</span>
                    <p>{contactNumber}</p>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-start">
                <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-3" style={{ borderRadius: '50%', width: '30px', height: '30px',marginLeft:'10px',padding:'18px' }}>
                  <FontAwesomeIcon icon={faWhatsapp} className="text-white mr-3" size="lg" />
                  </div>
                  <div>
                    <span className="font-weight-bold">WhatsApp</span>
                    <p>{contactNumber}</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex align-items-start">
                <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-3" style={{ borderRadius: '50%', width: '30px', height: '30px',marginLeft:'10px',padding:'18px' }}>
                  <FontAwesomeIcon icon={faEnvelope} className="text-white mr-3" size="lg" />
                  </div>
                  <div>
                    <span className="font-weight-bold">Email Us</span>
                    <p>
                    {email}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4">
            <h1 className="font-weight-bold mb-4" style={{ fontFamily: "Arial, sans-serif", fontSize: "2.5rem" }}>Resource Link</h1>
            <ul className="list-unstyled" style={{ padding: '10px 0' }}>
                <li style={{ marginBottom: '10px' }}>
                  <a href="#about" className="text-white" style={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem', textDecoration: 'none' }}>
                   Our About 
                  </a>
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <a href="#services" className="text-white" style={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem', textDecoration: 'none' }}>
                  Our Services
                  </a>
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <a href="#portfolio" className="text-white" style={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem', textDecoration: 'none' }}>
                  Our Gallery
                  </a>
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <a href="#contact" className="text-white" style={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem', textDecoration: 'none' }}>
                    Contact Us
                  </a>
                </li>
                
              </ul>
          </div>


          </div>
        </div>
      </div>
      <div className="container">
      <div className="copyright text-center py-3 text-white">
        Developed by <strong><span ><a href='https://designblocks.in/'  target='_blank'>designblocks.in</a></span></strong> 
      </div>

      </div>
    </footer>
  );
}

export default Footer;
