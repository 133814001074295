import React from "react";
import { Helmet } from "react-helmet";
import { UrlProvider, useUrlContext } from "../Context/UrlContext"; // Import useUrlContext for accessing loading state
import About from "../components/About";
import Contact from "../components/Contact";
import Footer from "../components/Fotter"; // Corrected the component name from Fotter to Footer
import Gallery from "../components/Gallery";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Sbuttons from "../components/Sbuttons";
import Services from "../components/Services";
import CustomSection from "../components/CustomSection";
import Seo from "../components/Seo";
import Inactive from "../components/Inactive";

const Loader = () => (
  <div className="three-body">
    <div className="three-body__dot"></div>
    <div className="three-body__dot"></div>
    <div className="three-body__dot"></div>
  </div>
);

const HomePageContent = () => {
  const { loading, status } = useUrlContext(); // Use the context to get the loading state

  if (loading) {
    return <Loader />;
  }


  if (status === "inActive") {
    return <Inactive />;
  }

  return (
    <>
      <Seo />
      <Header />
      <Hero />
      <About />
      <Services />
      <Gallery />
      <CustomSection />
      <Contact />
      <Footer />
      <Sbuttons />
    </>
  );
};

function HomePage() {
  return (
    <UrlProvider>
      <HomePageContent />
    </UrlProvider>
  );
}

export default HomePage;
