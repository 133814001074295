// src/components/SEO.js

import React from "react";
import { Helmet } from "react-helmet";
import { useUrlContext } from "../Context/UrlContext"; // Adjust the path as necessary

const SEO = () => {
  const { seoData, lastSegment } = useUrlContext();

  const seoTitle = seoData?.seoTitle || "QRDCards";
  const seoDescription = seoData?.seoDescription || "Design Blocks Qrdcards.";
  const keywords = seoData?.keywords || "BussinessCard, Qrdcards, DesignBlocks";
  const metaImage = seoData?.metaImage || "/default-image.jpg"; // Fallback image URL
  const canonicalUrl = `https://qrdcard.com/${lastSegment}`;

  return (
    <Helmet>
      <title>{seoTitle}</title>
      <meta name="description" content={seoDescription} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={canonicalUrl} />
      <link rel="icon" type="image/png" href={metaImage} sizes="16x16" /> 

      {/* Open Graph meta tags */}
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="QRDCards" />

      {/* Twitter Card meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={seoDescription} />
      <meta name="twitter:image" content={metaImage} />
      <meta name="twitter:url" content={canonicalUrl} />
    </Helmet>
  );
};

export default SEO;
