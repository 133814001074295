import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CustomSection = () => {
  const [nrmlContent, setNrmlContent] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    // Get the last segment of the URL
    const urlPath = window.location.pathname;
    const lastSegment = urlPath.substring(urlPath.lastIndexOf('/') + 1);

    // If the last segment is empty, do not render anything
    if (!lastSegment) {
      setNrmlContent('');
      return;
    }

    // Fetch Nrml content from the API
    const fetchNrmlContent = async () => {
      try {
        const response = await axios.get(`https://api.qrdcard.com/api/section?url=${lastSegment}`);
        const nrmlContent = response.data.sectionContent;
        setNrmlContent(nrmlContent);
      } catch (err) {
        setError('');
      }
    };

    fetchNrmlContent();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!nrmlContent) {
    return null; // Do not render anything if no content is available
  }

  // Function to extract the inner HTML content
  const extractInnerHtml = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return doc.body.innerHTML;
  };

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: extractInnerHtml(nrmlContent) }} style={{ textAlign: 'justify' }}></div>
    </div>
  );
};

export default CustomSection;
