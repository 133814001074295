import React, { useState, useEffect } from 'react';
import { Shimmer } from 'react-shimmer';
import { useUrlContext } from '../Context/UrlContext';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact() {
  const { aboutData } = useUrlContext();
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', subject: '', message: '' });
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const location = useLocation();
  const lastSegment = location.pathname.split('/').filter(Boolean).pop();

  useEffect(() => {
    if (aboutData) {
      setFormData((prevFormData) => ({ ...prevFormData, toMail: aboutData.email, toName: lastSegment }));
    }
  }, [aboutData]);

  if (!aboutData) {
    return (
      <section id="contact" className="contact" style={{ paddingBottom: '50px' }}>
        <Shimmer width={300} height={200} />
      </section>
    );
  }

  const { address, contactNumber, email, mapURL } = aboutData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setErrors([]);
    setSuccessMessage('');

    try {
      const response = await axios.post('https://api.qrdcard.com/api/url/sendmail', { ...formData });
      setSuccessMessage(response.data.message);
      setFormData({ name: '', email: '', phone: '', subject: '', message: '', toMail: aboutData.email, toName: lastSegment });
      toast.success('Email sent successfully!');
    } catch (error) {
      setErrors([error.response?.data?.message || 'An error occurred. Please try again.']);
      toast.error('An error occurred. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <section id="contact" className="contact" style={{ paddingBottom: '50px' }}>
      <div className="container">
        <div className="section-title">
          <h2>Contact</h2>
        </div>

        <div className="row" data-aos-delay="100">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-12">
                <div className="info-box">
                  <i className="bi bi-geo-alt-fill text-success"></i>
                  <h3>Our Address</h3>
                  <p>{address}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="bi bi-envelope-fill text-success"></i>
                  <h3>Email Us</h3>
                  <p>{email}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="bi bi-telephone-fill text-success"></i>
                  <h3>Call Us</h3>
                  <p>{contactNumber}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <form onSubmit={handleSubmit} className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    required
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    id="phone"
                    placeholder="Your Phone"
                    required
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-12 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="email"
                    placeholder="Your Email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  name="subject"
                  className="form-control"
                  id="subject"
                  placeholder="Subject"
                  required
                  value={formData.subject}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  name="message"
                  rows={5}
                  placeholder="Message"
                  required
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
              <div className="text-center mt-3">
                <button type="submit" className='btn-primary' style={{backgroundColor:'green'}} disabled={submitting}>Submit</button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-lg-12 mt-5">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                title="Map"
                width="100%"
                height="400px"
                id="gmap_canvas"
                src={mapURL}
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              />
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </section>
  );
}

export default Contact;
