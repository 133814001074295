import React from 'react';
import cusImage from '../constants/cus.png';
import { useUrlContext } from '../Context/UrlContext'; // Import the custom hook to access URL context

function Sbuttons() {
  const { aboutData ,socailMedia } = useUrlContext(); // Assuming aboutData contains contactNumber and email

  // If aboutData is null or undefined, you can handle loading state or return null
  if (!aboutData) {
    return null; // or return loading state if needed
  }


  const { contactNumber, email } = aboutData;
  const {youtube ,whatsapp,twitter,linkedin,instagram,facebook} = socailMedia;

  return (
    <div className="sbuttons">
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.css"
        rel="stylesheet"
      />
      <link rel="stylesheet" href="style.css" />
      <a href={`mailto:${email}`} target="_blank" className="sbutton mail" tooltip="Mail">
        <i className="fa fa-envelope" />
      </a>
      <a href={`tel:${contactNumber}`} target="_blank" className="sbutton call" tooltip="Call">
        <i className="fa fa-phone" />
      </a>
      <a
        href={`https://api.whatsapp.com/send?phone=${whatsapp}&text=`}
        className="sbutton whatsapp"
        target="_blank"
        tooltip="WhatsApp"
      >
        <i className="fab fa-whatsapp" />
      </a>
      <a target="_blank" className="sbutton mainsbutton" tooltip-active="contact">
        <img
          src={cusImage}
          alt="Contact"
          style={{ width: 39, paddingTop: 9 }}
        />
      </a>
    </div>
  );
}

export default Sbuttons;
