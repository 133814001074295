import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const UrlContext = createContext();

export const UrlProvider = ({ children }) => {
  const location = useLocation();
  const lastSegment = location.pathname.split('/').filter(Boolean).pop();

  const [servicesData, setServicesData] = useState(JSON.parse(localStorage.getItem('servicesData')) || null);
  const [slidesData, setSlidesData] = useState(JSON.parse(localStorage.getItem('slidesData')) || null);
  const [galleryData, setGalleryData] = useState(JSON.parse(localStorage.getItem('galleryData')) || null);
  const [aboutData, setAboutData] = useState(JSON.parse(localStorage.getItem('aboutData')) || null);
  const [socailMedia, setSocialMedia] = useState(JSON.parse(localStorage.getItem('socialMedia')) || null);
  const [seoData,setSeoData] = useState(JSON.parse(localStorage.getItem('seoData')) || null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [status, setStatus] = useState(null); // New state for URL status

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First check the status of the URL
        const statusResponse = await axios.get(`https://api.qrdcard.com/api/url/checkStatus?url=${lastSegment}`);
        setStatus(statusResponse.data.status); // Update status state
        if (statusResponse.data.status === "active") {
        // If status is active, fetch the other data concurrently
        const [servicesResponse, slidesResponse, galleryResponse, aboutResponse, socailMediaResponse, seoResponse] = await Promise.all([
          axios.get(`https://api.qrdcard.com/api/services?url=${lastSegment}`),
          axios.get(`https://api.qrdcard.com/api/slides?url=${lastSegment}`),
          axios.get(`https://api.qrdcard.com/api/gallery?url=${lastSegment}`),
          axios.get(`https://api.qrdcard.com/api/about?url=${lastSegment}`),
          axios.get(`https://api.qrdcard.com/api/socialmedia?url=${lastSegment}`),
          axios.get(`https://api.qrdcard.com/api/seo?url=${lastSegment}`),

          
        ]);

        setServicesData(servicesResponse.data);
        localStorage.setItem('servicesData', JSON.stringify(servicesResponse.data));

        setSlidesData(slidesResponse.data);
        localStorage.setItem('slidesData', JSON.stringify(slidesResponse.data));

        setGalleryData(galleryResponse.data);
        localStorage.setItem('galleryData', JSON.stringify(galleryResponse.data));

        setAboutData(aboutResponse.data);
        localStorage.setItem('aboutData', JSON.stringify(aboutResponse.data));

        setSocialMedia(socailMediaResponse.data)
        
        setSeoData(seoResponse.data);
        localStorage.setItem('seoData', JSON.stringify(seoResponse.data));
      }

        // Set loading to false once data is fetched
        setLoading(false);
      } catch (error) {
        console.error("Error fetching API data:", error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    if (lastSegment) {
      fetchData(); // Call fetchData only if lastSegment exists
    }
  }, [lastSegment]); // Fetch data whenever lastSegment changes

  return (
    <UrlContext.Provider value={{ servicesData, slidesData, galleryData, aboutData, socailMedia, loading , lastSegment, seoData ,status }}>
      {children}
    </UrlContext.Provider>
  );
};

export const useUrlContext = () => {
  return useContext(UrlContext);
};
