import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DefaultPage from "./pages/DefaultPage";
import HomePage from "./pages/HomePage";
import MenuPage from "./pages/MenuPage";
import { UrlProvider } from "./Context/UrlContext";
import './index.css';  // Ensure this file contains Tailwind's directives
import ServicesPage from "./pages/ServiesPage";  // Corrected typo in import
import Header from "./components/Header";
import Footer from "./components/Fotter";
import Sbuttons from "./components/Sbuttons";


function App() {
  return (
    <Router>
      <UrlProvider>
        <Routes>
          <Route path="/" element={<DefaultPage />} />
          <Route path="/:lastSegment" element={<HomePage />} />
          <Route path="/menu/:guid" element={<MenuPage />} />
          {/* <Route path="/service/:index" element={<ServicesPage />} />   */}
        </Routes>
      </UrlProvider>
    </Router>
  );
}

export default App;
