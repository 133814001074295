import React from "react";

const Inactive = () => {
  return (
    <div>
      <h1>Inactive</h1>
      <p>The URL is inactive. Please Contact <a href="https://designblocks.in/" target="_blank" style={{color:'#173b6c'}}><strong>DesignBlocks.in</strong> </a></p>
    </div>
  );
};

export default Inactive;
